import React, { Component } from 'react';
import Layout from '../../components/layout';

//Components
import ManagedPumpContainer from '../../components/manage-pump-container'

//PARSE
import { GetTicketTypes } from '../../services/parse-bridge'

//NPM
import styled from 'styled-components'

const IssueContainer = styled.div`
    padding: 40px 20px;
`

const Title = styled.h1`
    color: #5D323B;
    text-transform: uppercase;
    font-size: 28px;
    margin: 50px 0 5px 0;
`

const Subtitle = styled.p`
    margin: 0 0 40px 0;
    color: #5D323B;
    font-size: 16px;
`

const SearchBox = styled.input`
    padding: 15px 15px;
    width: 100%;
    border: 1px solid #E9E9E9;
    border-radius: 3px;
    font-size: 18px;

    &:focus {
        outline: none;
    }
`

const SelectedContainer = styled.div`
    margin: 20px 0;
`

const SelectedItem = styled.div`
    padding: 10px 15px;
    font-family: Avenir-Black;
    border-radius: 6px;
    border: 1px solid #EDEDED;
    box-shadow: 0 3px 6px rgba(200,200,200,0.24);
    margin: 8px 0;
    border-left: 7px solid #fc444e;
    transition: 1s ease;

    //Add Fade in animation
    
`

const RemoveButton = styled.button`
    color: #FF4747;
    font-style; italic;
    background: none;
    border: none;
    font-family: Avenir-Heavy;
    font-size: 15px;
    cursor: pointer;
`

const TicketType = styled.div`
    border: 1px solid #EDEDED;
    max-width: 700px;
    margin: 10px auto;
    border-radius: 20px;
    display: flex;
    flex-basis: 70px;
    justify-items: center;
    align-items: center;
    box-shadow: 0 4px 10px rgba(200,200,200,0.24);
`
const TicketCount = styled.h1`
    flex-basis: 10%;
    margin: 0;
    height: 100%;
    width: 100%;
    padding: 20px;
    background-color: #Fc444e;
    border-top-left-radius: inherit;
    border-bottom-left-radius: inherit;
    color: white;
`
const TicketName = styled.h1`
    padding: 0 15px;
    margin: 0;
    color: #5B5B5B;
    font-size: 22px;
`

const TransferContainer = styled.div`
    text-align: center;
    border: 1px solid #EDEDED;
    box-shadow: 0 4px 10px rgba(200,200,200,0.24);
    border-radius: 50px;
    padding: 30px;
    max-width: 650px;
    margin: 0 auto;

    
`
const TransferInput = styled.input`
    border: 1px solid #D7D7D7;
    text-align: center;
    background-color: #F3F3F3;
    padding: 20px 5px;
    font-size: 40px;
    font-family: Avenir-Black;
    border-radius: 60px;
    width: 200px;
    margin: 0 auto;

    &:focus {
        outline: none
    }
`

const TransferText = styled.h1`
    color: #fc444e;
    font-size: 20px;
    margin: 10px 0;
`

class IssueTicketsPage extends Component {

    constructor(props) {
        super(props)

        this.state = {
            selectedUsers: ["Andel Husbands", "Jonathan Agarrat"],
            searchList: []
        }
    }



    componentDidMount() {

        this.setState({ pump: this.props.location.state.pump });
        let searchId = String(this.props.location.search)
        let id = searchId.substr(2, 10)
        this.setState({ pumpId: this.props.location.state.pump.id })

        console.log("getting tickets for: " + id)
        GetTicketTypes(id).then(ticketTypes => {
            this.setState({
                ticketTypes: ticketTypes
            }, () => console.log(this.state))
        })
    }

    render() {
        return (
            <Layout>
                <IssueContainer>
                    {this.state.pump ? <ManagedPumpContainer
                        name={this.state.pump.name}
                        flyer={this.state.pump.flyer}
                    /> : null}
                    <Title>Issue Tickets</Title>
                    <Subtitle>Search for the people you would like to issue tickets to</Subtitle>
                    <SearchBox type="text" placeholder="Search by name..." />

                    <SelectedContainer>
                        {
                            this.state.selectedUsers.map(user => (
                                <SelectedItem>{user} <RemoveButton>Remove</RemoveButton></SelectedItem>
                            ))
                        }
                    </SelectedContainer>


                    <Title>Current Ticket Types</Title>
                    <TicketType>
                        <TicketCount>200</TicketCount>
                        <TicketName>Early Bird</TicketName>
                    </TicketType>
                    <TicketType>
                        <TicketCount>150</TicketCount>
                        <TicketName>General</TicketName>
                    </TicketType>
                    <TicketType>
                        <TicketCount>200</TicketCount>
                        <TicketName>VIP</TicketName>
                    </TicketType>

                    <TransferContainer>
                        <TransferText>I want to transfer</TransferText>
                        <TransferInput type="number" placeholder="0" />
                        <TransferText>tickets to</TransferText>

                    </TransferContainer>

                </IssueContainer>

            </Layout>
        );
    }
}

export default IssueTicketsPage;